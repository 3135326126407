<template>
  <div class="container">
    <div class="bg_wra">关于我们</div>
    <div class="title">公司简介</div>
    <div class="part_con part_padding">
      <a-row>
        <a-col :xl="14" :md="24">
          <div class="intro_text">
              <!--
              久智科技专注于智慧社区、智慧园区、智慧公寓生态系统研发、智慧社区商业运营服务一体化，
              -->
            <p style="padding-left: 30px">久智科技专注于智慧社区、智慧园区、智慧公寓生态系统研发、智慧社区商业运营服务一体化，
            </p>
            <p>通过物联网、云技术、大数据及人工智能的创新科技，实现社区数字化、线上化、数据化、智慧化，联接更多商业服务及公共服务，持续赋能地产、物业及空间运营公司，服务政府基层单位治理，为社区家庭提供更快捷更满意的增值服务，创领全新的智慧社区新体验。久智科技核心打造以“一平台四集群系统”的智慧社区整体解决方案。</p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智慧社区平台包括：面向用户的智慧社区运营系统、全面整合社区智能硬件的智慧物联网系统、面向物业管理的智慧物业管理系统、为政府及企业运营管理赋能的战图大数据服务系统。
            </p>
          </div>
        </a-col>
        <a-col :xl="10" :md="24">
          <img src="../assets/image/abort_img.png" alt="">
        </a-col>
      </a-row>
    </div>
    <div class="title">企业文化</div>
    <div class="bg_culture">
      <div class="part_con">
        <a-row :gutter="40">
          <a-col :xl="8" :md="24">
            <div class="slogin_item">
              <div class="item_text">
                <span class="item_title">使命</span>
              </div>
              <div class="item_text">技术创造价值 服务成就卓越</div>
            </div>
          </a-col>
          <a-col :xl="8" :md="24">
            <div class="slogin_item">
              <div class="item_text">
                <span class="item_title">愿景</span>
              </div>
              <div class="item_text">最值得信赖的技术服务商</div>
            </div>
          </a-col>
          <a-col :xl="8" :md="24">
            <div class="slogin_item">
              <div class="item_text">
                <span class="item_title">价值观</span>
              </div>
              <div class="item_text">用户为本 科技向善</div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="title">服务理念</div>
    <div class="part_con">
      <a-row>
        <a-col :xl="8" :md="24" class="idea_col">
          <div class="idea_wra idea1">
            <div class="content">
              <div class="subtitle">专业技术</div>
              <div class="desc">我们拥有行业专业技术实力与资深工程开发团队，为您的商业变现及产品需求保驾护航。</div>
            </div>
          </div>
        </a-col>
        <a-col :xl="8" :md="24" class="idea_col">
          <div class="idea_wra idea2">
            <div class="content">
              <div class="subtitle">贴心服务</div>
              <div class="desc">我们始终坚持优质的服务理念，无论任何时候您遇到困难，我们都会及时提供精准有效的解决方案。</div>
            </div>
          </div>
        </a-col>
        <a-col :xl="8" :md="24" class="idea_col">
          <div class="idea_wra idea3">
            <div class="content">
              <div class="subtitle">创业伙伴</div>
              <div class="desc">我们把每一位客户都当作创业伙伴，期盼在未来成长的道路上一路相伴，携手前行。</div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="title">联系我们</div>
    <div class="part_con">
      <a-row type="flex" class="contact">
        <a-col flex="85px">
          <p class="title_l">业务合作</p>
          <p class="title_s">qyx@9zhinet.com</p>
        </a-col>
        <a-col :flex="1"><div class="dv"></div></a-col>
        <a-col flex="85px">
          <p class="title_l">其它合作</p>
          <p class="title_s">ssq@9zhinet.com</p>
        </a-col>
        <a-col :flex="1"><div class="dv"></div></a-col>
        <a-col flex="85px">
          <p class="title_l">简历投递</p>
          <p class="title_s">hr@9zhinet.com</p>
        </a-col>
        <a-col :flex="1"><div class="dv"></div></a-col>
        <a-col flex="100px">
          <p class="title_l">联系电话</p>
          <p class="title_s">17719827554</p>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  line-height: 480px;
  text-align: center;
  font-size: 40px;
  color: #fff;
  background: url("../assets/image/bg_abort.png") no-repeat center;
  background-size: cover;
}

.title {
  font-size: 32px;
  color: #333;
  text-align: center;
  padding: 50px 0;
}

.part_con {
  width: 1200px;
  margin: 0 auto;

  .intro_text {
    padding-top: 100px;
    padding-right: 50px;
    color: #333;
    p {
      margin: 0;
      line-height: 35px;
    }
  }
}

.bg_culture {
  height: 288px;
  background: url("../assets/image/bg_abort_2.png") no-repeat center;
  background-size: cover;

  .slogin_item {
    margin-top: 76px;
    height: 136px;
    background: rgba(13, 25, 49, 0.5);
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all .3s linear;
    position: relative;
    top: 0;

    .item_text {
      line-height: 30px;
    }

    .item_title {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 17px;
        transform: skew(-10deg);
        background-color: #2590f2;
        top: -10px;
        left: -14px;
        opacity: 0;
        transition: all .3s linear;
      }

      &:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 17px;
        transform: skew(-10deg);
        background-color: #2590f2;
        top: 10px;
        right: -14px;
        opacity: 0;
        transition: all .3s linear;
      }
    }

    &:hover {
      top: -16px;

      .item_title:before {
        width: 6px;
        top: 0;
        left: -14px;
        opacity: 1;
      }

      .item_title:after {
        width: 6px;
        top: 0;
        right: -14px;
        opacity: 1;
      }
    }
  }
}
.idea_col{
  overflow: hidden;
  .idea_wra{
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s linear;
    position: relative;
    color: #fff;
    &:hover{
      transform: scale(1.1);
      .content{
        transform: translateY(-80px);
        .desc{
          opacity: 1;
        }
      }
    }
    .content{
      padding: 0 45px;
      position: absolute;
      top: 390px;
      transition: all .3s linear;
      .subtitle{
        text-align: center;
        font-size: 24px;
      }
      .desc{
        font-size: 16px;
        margin-top: 20px;
        opacity: 0;
        transition: all .3s linear;
      }
    }
  }
  .idea1{
    background-image: url("../assets/image/bg_abort_s1.png");
  }
  .idea2{
    background-image: url("../assets/image/bg_abort_s2.png");
  }
  .idea3{
    background-image: url("../assets/image/bg_abort_s3.png");
  }
}
.contact{
  text-align: center;
  padding-bottom: 40px;
  .title_l{
    font-size: 20px;
    color: #333;
  }
  .title_s{
    color: #666;
  }
  .dv{
    width: 1px;
    height: 75px;
    background-color: #979797;
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px){
  .bg_wra{
    height: 300px;
    line-height: 300px;
    background-position: top;
  }
  .part_con{
    width: 100%;
    .intro_text{
      padding: 0 0 10px;
    }
    img{
      width: 100%;
    }
  }
  .part_padding{
    padding: 0 20px;
  }
  .bg_culture{
    /deep/ .ant-row{
      padding: 0 10px;
      margin: 0 !important;
    }
    /deep/ .ant-col{
      padding: 0 !important;
    }
    .slogin_item{
      height: 82px;
      margin-top: 10px;
      &:hover{
        top: 0;
      }
      .item_title{
        &:before{
          opacity: 1;
          top: 0;
        }
        &:after{
          opacity: 1;
          top: 0;
        }
      }
    }
  }
  .idea_col{
    .idea_wra{
      height: 200px;
      background-position: top;
      .content{
        top: 30px !important;
        transform: none !important;
        .desc{
          opacity: 1;
        }
      }
    }
  }
}
</style>
